@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Josefin Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
  background-color: #000000;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans", sans-serif;
}
h5 {
  padding-top: 30px;
  font-size: 0.9rem;
  color: #75dfee;
}
h2 {
  font-size: 2rem;
  color: #75dfee;
  font-weight: 400;
}
h4 {
  font-size: 1.8rem;
  color: #75dfee;
  font-weight: 600 ;
}
p1 {
  color: #75dfee;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
.home-page-wrapper {
  --antd-wave-shadow-color: #75dfee;
  background-image: linear-gradient(to bottom, #0000009b 20%, rgba(0, 0, 0, 0.059) 80%), url(https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg);
  background-attachment: fixed, fixed;
  background-blend-mode: normal, normal;
  background-position: 0% 0%,70% 100%;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header0 {
  background: #000000;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu .ant-menu {
  background-color: #000;
  line-height: 62px;
  height: 64px;
}
.header0-menu .ant-menu a {
  display: block;
}
.header0-item-block {
  padding: 0 8px;
}
.header0-item-block > * {
  display: inline-block;
}
.header0-item,
.header0-item-child,
.header0-menu {
  background-color: #000;
}
.header0-item .ant-menu-sub .ant-menu-item,
.header0-item-child .ant-menu-sub .ant-menu-item,
.header0-menu .ant-menu-sub .ant-menu-item,
.header0-item .ant-menu-inline .ant-menu-item,
.header0-item-child .ant-menu-inline .ant-menu-item,
.header0-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header0-item .item-sub-item,
.header0-item-child .item-sub-item,
.header0-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header0-item .item-image,
.header0-item-child .item-image,
.header0-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header0-item .item-title,
.header0-item-child .item-title,
.header0-menu .item-title {
  font-size: 14px;
  color: #fff;
  margin-left: 46px;
}
.header0-item .item-content,
.header0-item-child .item-content,
.header0-menu .item-content {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0-menu {
    background-color: #000;
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-menu li {
    padding: 0 24px;
  }
  .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0-menu .item-sub-item {
    padding: 8px 0;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header0-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner0 {
  width: 100%;
  height: 90vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 14px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 23px;
  font-size: 22px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content2-wrapper {
  height: 360px;
}
.content2-wrapper .content2 {
  height: 100%;
  padding: 0 24px;
}
.content2-wrapper .content2-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content2-wrapper .content2-img span {
  display: block;
  width: 250px;
}
.content2-wrapper .content2-img span img {
  display: block;
}
.content2-wrapper .content2-text {
  padding: 0 32px;
  height: 100%;
}
.content2-wrapper .content2-text .content2-content,
.content2-wrapper .content2-text .content2-title {
  position: relative !important;
}
.content2-wrapper .content2-text .content2-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content2-wrapper .content2-text .content2-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content2-wrapper {
    height: 600px;
  }
  .content2-wrapper .content2-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content2-wrapper .content2-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content2-wrapper .content2-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content2-wrapper .content2-text .content2-content,
  .content2-wrapper .content2-text .content2-title {
    width: 100%;
    top: auto;
  }
  .content2-wrapper .content2-text .content2-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content9-wrapper {
  padding-bottom: 64px;
}
.content9-wrapper.home-page-wrapper {
  overflow: initial;
}
.content9-wrapper .content9 {
  min-height: 800px;
  padding: 64px 0 0;
}
.content9-wrapper .timeline {
  position: relative;
}
.content9-wrapper .timeline:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: calc(100% - 108px);
  margin: 34px 0;
  border-left: 2px #ebedf0 dashed;
}
.content9-wrapper .block-wrapper {
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  position: relative;
  margin-bottom: 70px;
  min-height: 160px;
}
.content9-wrapper .block-wrapper:last-child {
  margin-bottom: 0;
}
.content9-wrapper .image-wrapper,
.content9-wrapper .text-wrapper {
  width: 50%;
}
.content9-wrapper .image-wrapper {
  text-align: center;
}
.content9-wrapper .image-wrapper .block-img,
.content9-wrapper .image-wrapper .name-wrapper {
  float: right;
  clear: both;
  width: 262px;
  margin: auto;
}
.content9-wrapper .image-wrapper .block-img {
  height: 98px;
}
.content9-wrapper .image-wrapper .block-img img {
  height: 100%;
}
.content9-wrapper .image-wrapper .name-wrapper {
  margin: 16px 0 0;
}
.content9-wrapper .image-wrapper .name-wrapper .block-name {
  font-size: 12px;
  margin-bottom: 4px;
}
.content9-wrapper .image-wrapper .name-wrapper .block-post {
  font-size: 12px;
  color: #697b8c;
}
.content9-wrapper .text-wrapper {
  padding-left: 40px;
}
.content9-wrapper .text-wrapper .block-time {
  font-size: 15px;
  color: #697b8c;
  line-height: 18px;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-title {
  margin: -12px 0 12px;
  font-size: 15px;
  font-weight: 300;
  position: relative;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-icon {
  position: absolute;
  left: -40px;
  -webkit-transform: translateX(calc(-50% + 1px));
          transform: translateX(calc(-50% + 1px));
}
.content9-wrapper .text-wrapper .block-content {
  width: 300px;
  color: #314659;
  font-size: 12px;
  min-height: 18px;
}
@media screen and (max-width: 767px) {
  .content9-wrapper {
    padding-bottom: 0;
  }
  .content9-wrapper .content9 {
    padding: 64px 24px;
  }
  .content9-wrapper .timeline:before {
    left: 0;
    height: 100%;
    margin: 0;
  }
  .content9-wrapper .block-wrapper {
    display: block;
    min-height: 100px;
    margin-bottom: 54px;
  }
  .content9-wrapper .image-wrapper,
  .content9-wrapper .text-wrapper {
    width: 100%;
  }
  .content9-wrapper .image-wrapper .block-img {
    display: none;
  }
  .content9-wrapper .image-wrapper .name-wrapper {
    text-align: left;
    float: left;
    width: 100%;
    padding-left: 40px;
    margin: auto;
  }
  .content9-wrapper .image-wrapper .block-name,
  .content9-wrapper .image-wrapper .block-post {
    display: inline-block;
  }
  .content9-wrapper .image-wrapper .block-name {
    margin-right: 8px;
  }
  .content9-wrapper .text-wrapper .block-content {
    display: none;
  }
  .content9-wrapper .text-wrapper .block-title {
    font-size: 14px;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
}
.content7-wrapper {
  min-height: 720px;
}
.content7-wrapper .content7 > h1,
.content7-wrapper .content7 > p {
  text-align: center;
}
.content7-wrapper .content7-tag i {
  width: 12px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.content7-wrapper .content7-tag-name {
  display: inline-block;
}
.content7-wrapper .content7 .ant-tabs-bar {
  text-align: center;
}
.content7-wrapper .content7 .ant-tabs .ant-tabs-nav {
  float: none;
  text-align: center;
}
.content7-wrapper .content7-tabs-wrapper {
  position: relative;
  margin-top: 24px;
}
.content7-wrapper .content7-content {
  display: flex;
  align-items: center;
}
.content7-wrapper .content7-text {
  padding: 24px 48px;
}
.content7-wrapper .content7-img {
  padding: 24px 48px;
}
.content7-wrapper .content7 .ant-tabs-tabpane {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .content7-wrapper {
    min-height: 980px;
    overflow: hidden;
  }
  .content7-wrapper .content7 {
    max-width: 100%;
  }
  .content7-wrapper .content7-content {
    display: block;
  }
  .content7-wrapper .content7-text,
  .content7-wrapper .content7-img {
    text-align: left;
    padding: 0;
  }
  .content7-wrapper .content7-img {
    margin-top: 32px;
  }
  .content7-wrapper .content7 .ant-tabs-bar {
    width: auto;
  }
  .content7-wrapper .content7 .ant-tabs-bar .ant-tabs-nav {
    float: left;
  }
}
.content11-wrapper {
  height: 480px;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 96px;
}
.content11-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content11-wrapper .button {
  box-shadow: 0 8px 16px #0554b7;
  background: linear-gradient(to right, #05cbff, #1e5aff) !important;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border: 0;
  border-radius: 21px;
  color: #fff;
  width: 128px;
  padding: 0 15px;
  display: inline-block;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
}
.content11-wrapper .button:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  box-shadow: 0 12px 20px #0554b7;
}
.content11-wrapper .button:active {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  box-shadow: 0 4px 8px #0554b7;
}
.content11-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content11-wrapper {
    padding-bottom: 0;
  }
}
.footer1-wrapper {
  background: #000000;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 44px 24px 0px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #ffffff;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  font-size: 13px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 40%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
#Banner0_1 .banner0-text-wrapper > .banner0-content {
  font-weight: lighter;
  color: #75dfee;
  text-align: center;
}
#Banner0_1 .banner0-text-wrapper > .lewqyjhsaq-editor_css {
  display: flex;
  box-shadow: none;
}
#Banner0_1 .banner0-text-wrapper > .lews0l2kjf-editor_css {
  color: #fff;
  text-align: left;
  box-shadow: 9px 9px 9px rgba(0, 0, 0, 0.35);
}
#Feature2_0 .ant-row > .ant-col > .ley5rsmpcba-editor_css {
  font-size: 17px;
  width: 250px;
  height: 250px;
  opacity: 0.96;
  padding-bottom: 10px;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Nav0_1 .ley7qv8qxl-editor_css {
  color: #75dfee;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Nav0_1 .home-page > .leya0oyxhrs-editor_css {
  color: #ffffff;
  text-decoration: overline;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Feature0_1 .ant-col > .content0-block-item > .leycio1i9ek-editor_css {
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Banner0_1 .lewr42hrdp-editor_css {
  display: block;
  color: #75dfee;
}
#Feature1_0 .ant-row > .ant-col > .leyez1yqgds-editor_css {
  color: #bfbfbf;
  overflow: hidden;
}
#Feature2_0 .ant-row > .ant-col > .ley5wbcytj-editor_css {
  font-family: "Josefin Sans";
  font-weight: bold;
  color: #75dfee;
  padding-top: 10px;
  text-shadow: 5px 5px 13px rgba(0, 162, 174, 0.36);
  transition: color 0s ease-in 0s;
}
#Banner0_1.lewqwr2v3d7-editor_css {
  color: #75dfee;
  background-color: #0a0a0a;
  background-attachment: fixed;
  background-blend-mode: normal;
  background-position: center center;
  background-repeat: repeat;
  background-size: cover;
  background-clip: padding-box;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Feature0_1 .ant-col > .content0-block-item > .leyc9kxiqe-editor_css {
  font-size: 15px;
  color: #bfbfbf;
  text-shadow: 5px 5px 13px rgba(0, 162, 174, 0.36);
}
#Feature0_1 .ant-col > .content0-block-item > .leyc9297a9k-editor_css {
  font-size: 15px;
  color: #bfbfbf;
  text-shadow: 5px 5px 13px rgba(0, 162, 174, 0.36);
}
#Feature2_0 .ant-row > .lezcg15pgpt-editor_css {
  background-clip: padding-box;
}
#Feature1_0 .ant-row > .ant-col > .leyeynwb1mj-editor_css {
  width: 250px;
  opacity: 0.96;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0);
}
#Footer2_0 .home-page > .links > .lezhbaipvys-editor_css {
  height: 50px;
}
#Footer2_0 .home-page > .copyright > .lezh90jkro-editor_css {
  color: #75dfee;
}
#Nav0_1.ley9wf00529-editor_css {
  background-color: #000000;
}
#Nav0_1 .header0-menu > .ant-menu > .ley9icmt1y9-editor_css {
  background-color: #000000;
}
#Nav0_1 .header0-menu > .ant-menu > .ley9vtxpylp-editor_css {
  background-color: #000000;
}
#Nav0_1 .header0-menu > .ant-menu > .ley9uy5cxm-editor_css {
  background-color: #000000;
}
#Feature5_0 .ant-tabs-tabpane > .ant-row > .lf2830ull76-editor_css {
  box-shadow: none;
}
#Feature5_0 .home-page > .title-wrapper > .lf294qfz5x9-editor_css {
  height: 20px;
  position: absolute;
  opacity: 0.42;
}
#Feature5_0 .ant-tabs-content > .ant-tabs-tabpane > .lf296y7lx8c-editor_css {
  position: -webkit-sticky;
  position: sticky;
}
#Feature5_0 .home-page > .title-wrapper > .lf297kvq5zs-editor_css {
  display: flex;
  align-items: flex-start;
}
#Feature5_0 .home-page > .title-wrapper > .lf297kvq5zs-editor_css:hover {
  height: 50px;
  position: absolute;
}
#Feature5_0 .home-page > .title-wrapper > .lf297kvq5zs-editor_css:active {
  display: inline;
}
#Feature5_0 .home-page > .title-wrapper > .lf29jk2bqki-editor_css {
  cursor: move;
  background-image: url('https://saarbk.github.io/Quancila-logo-with-cat-blue.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat-x;
  background-size: auto;
  background-clip: border-box;
}
#Feature5_0 .home-page > .lf29rhj48ob-editor_css {
  display: inline;
  justify-content: center;
}
#Feature5_0 > .content7-tag > .lf2a1g873ji-editor_css {
  color: #75dfee;
}
#Feature5_0 > .content7-tag > .lf2a0e092c-editor_css {
  color: #75dfee;
}
#Feature5_0 .ant-tabs-tab-active > .content7-tag > .lf29xotjaur-editor_css {
  color: #75dfee;
}
#Feature5_0 div > .content7-tabs-wrapper > .ant-tabs-line {
  color: #75dfee;
}
#Feature5_0 div > .content7-tabs-wrapper > .ant-tabs {
  color: #ffffff;
}
#Feature5_0 .ant-tabs-content > .ant-tabs-tabpane > .lf2aaz4gmf-editor_css {
  opacity: 0.94;
}
#Feature5_0 div > .content7-tabs-wrapper > .lf2a2329nwm-editor_css {
  color: #ffffff;
  opacity: 0.98;
}
#Feature5_0 div > .content7-tabs-wrapper > .ant-tabs-top {
  color: #75dfee;
}
#Feature5_0 .ant-tabs-tab-active > .content7-tag > .lf2a1g873ji-editor_css {
  color: #75dfee;
}
#Feature5_0 .home-page > .title-wrapper > .lf26pow71bs-editor_css {
  font-family: "Josefin Sans";
  font-weight: bold;
  color: #75dfee;
  text-shadow: 5px 5px 13px rgba(0, 162, 174, 0.36);
}
#Feature5_0 .ant-tabs-tab-active > .content7-tag > .lf29xeimtpg-editor_css {
  color: #75dfee;
}
#Feature5_0 .home-page > .title-wrapper > .lf29otjrx5-editor_css {
  display: flex;
  justify-content: center;
  color: #fbfbfb;
  height: 100px;
}
#Feature2_0 .ant-row > .ant-col > .ley5t36pcs-editor_css {
  color: #bfbfbf;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Feature0_1 .ant-col > .content0-block-item > .leyc9twhdyg-editor_css {
  font-size: 15px;
  color: #bfbfbf;
  text-shadow: 5px 5px 13px rgba(0, 162, 174, 0.361);
}
#Feature5_0 .ant-tabs-tabpane > .ant-row > .lf26ovwokc-editor_css {
  color: #bfbfbf;
}
#Feature1_0 .ant-row > .ant-col > .leyezlen46m-editor_css {
  font-family: "Josefin Sans";
  font-weight: bold;
  color: #75dfee;
  text-shadow: 5px 5px 13px rgba(0, 162, 174, 0.36);
}
#Banner0_1 .banner0-text-wrapper > .lewr5h422oi-editor_css {
  background-clip: padding-box;
  font-family: "Josefin Sans";
  font-weight: lighter;
  color: #75dfee;
  letter-spacing: 1px;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  text-shadow: 10px 5px 5px rgba(0, 0, 0, 0.35);
}
#Feature0_1 div > .ant-row > .lf2juwvglol-editor_css {
  margin: 0 0 22px;
}
#Feature2_0 .ley5u9wsk2-editor_css {
  width: 100%;
  max-width: 1400px;
  background-color: rgba(0, 0, 0, 0.95);
  background-image: url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 35% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Feature1_0 .leyexg4uwts-editor_css {
  max-width: 1400px;
  background-color: #000000;
  background-image: url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 85%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Feature0_1 .leyc9zxqwvd-editor_css {
  max-width: 1400px;
  position: static;
  background-image: url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg');
  background-attachment: local;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
  border-bottom-color: initial;
  padding: 64px 24px 34px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Feature0_1 .home-page > div > .leyc8xmoyv-editor_css {
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0);
  padding: 20px 0px 10px;
}
#Feature5_0 div > .ant-tabs-tab-active > .lf2kc1xyo2c-editor_css {
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Feature5_0 .ant-tabs-tab-active > .content7-tag > .lf2a0e092c-editor_css {
  color: #75dfee;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Feature5_0 .lf26o8r21y-editor_css {
  color: #fbfbfb;
  max-width: 1400px;
  background-color: #000000;
  background-image: url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
  padding: 35px 24px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Feature0_1 .home-page > .title-wrapper > .leyc7c2b4r-editor_css {
  font-family: "Josefin Sans";
  color: #75dfee;
  text-shadow: 5px 5px 13px rgba(0, 162, 174, 0.36);
  transition: width 2s ease 0s;
}
@media screen and (max-width: 767px) {
  #Feature0_1 .home-page > .title-wrapper > .leyc7c2b4r-editor_css {
    font-weight: bold;
    color: #75dfee;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
    transition: width 2s ease 0s;
  }
}
#Content11_1 .title-wrapper > .lf2km47gp6i-editor_css {
  color: #75dfee;
}
#Content11_1 .title-wrapper > .lf2kmdo94ib-editor_css {
  color: #75dfee;
}
#Feature5_0 .ant-tabs-tabpane > .ant-row > .lf29gx2smlh-editor_css {
  color: #bfbfbf;
}
#Content11_1 .title-wrapper > .lf2km3on18-editor_css {
  color: #bfbfbf;
}
#Content11_1 .title-wrapper > .lf2l1smhhme-editor_css {
  display: flex;
  justify-content: center;
  font-size: 7px;
  height: 50px;
}
#Content11_1.lf2khp9ycmo-editor_css {
  background-color: #000000;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.63) 0%, rgba(255, 255, 255, 0) 100%), url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg');
  background-attachment: local, scroll;
  background-blend-mode: normal, normal;
  background-position: 0% 0%, 100% 50%;
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  background-clip: padding-box, padding-box, padding-box;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Footer2_0 .footer2 {
  background-color: #000000;
}
#Footer2_0.lf2li953vi-editor_css {
  box-shadow: 5px 5px 5px #222222;
}
#Footer2_0 .lezh9qwra4-editor_css {
  background-color: #000000;
  box-shadow: 5px 5px 5px #000000;
}
#Footer1_1.footer1-wrapper {
  background-color: #000000;
}
#Footer1_1.lfl0r5zk7r-editor_css {
  min-height: 250px;
  background-color: #000000;
}
#Footer1_1 .footer1 > .lfl0tgre1s8-editor_css {
  border-top-color: rgba(233, 233, 233, 0.29);
}
#Footer1_1 .ant-row > .ant-col > .lfl0yob426h-editor_css {
  color: #ffffff;
}
#Footer1_1 .ant-row > .ant-col > .lfl0y4otxg9-editor_css {
  color: #ffffff;
}
#Footer1_1 .ant-row > .ant-col > .lfl0y33gh4d-editor_css {
  color: #fdfdfd;
}
#Footer1_1 .footer1 > .ant-row > .block {
  display: block;
}
#Footer1_1 .ant-row > .ant-col > .lfl0lb6n5ah-editor_css {
  display: flex;
  justify-content: space-around;
}
#Footer1_1 div > div > .lg0ixbekii-editor_css {
  width: 38px;
}
#Footer1_1 .ant-col > .lfl0lb6n5ah-editor_css > .lfl14iukf1-editor_css {
  max-width: 30px;
}
#Content9_0 .image-wrapper > .name-wrapper > .lhepe8b8m5-editor_css {
  display: none;
}
#Content9_0 .image-wrapper > .name-wrapper > .lhepeg772ma-editor_css {
  display: none;
}
#Content9_0 .image-wrapper > .name-wrapper > .lhepko1naf-editor_css {
  display: none;
}
#Content9_0 .image-wrapper > .name-wrapper > .lhepktkrcu-editor_css {
  display: none;
}
#Content9_0 .block-wrapper > .image-wrapper > .lhepkkh1lr-editor_css {
  max-width: 100%;
  max-height: 100%;
}
#Content9_0 .block-wrapper > .text-wrapper > .lhephglty8q-editor_css {
  display: none;
}
#Content9_0 .block-wrapper > .image-wrapper > .block-img {
  clear: left;
}
#Content9_0 .home-page > .title-wrapper > .lhenrgvwzj8-editor_css {
  color: #75dfee;
  text-shadow: 5px 5px 13px #00a2ae;
}
#Content9_0 .block-wrapper > .text-wrapper > .lheqfqs614t-editor_css {
  display: none;
}
#Content9_0 .block-wrapper > .text-wrapper > .lheqjdw5bqe-editor_css {
  color: #00a854;
}
#Content9_0 .block-wrapper > .text-wrapper > .lheqpeam5yu-editor_css {
  display: none;
  color: #fbfbfb;
}
#Content9_0 .home-page > .timeline > .lhes8r7ppsn-editor_css {
  min-height: 200px;
}
#Content9_0 .timeline > .block-wrapper > .lheos3216m-editor_css {
  justify-content: center;
  padding: 0 0 10px;
}
#Content9_0 .block-wrapper > .text-wrapper > .lheqfofzly-editor_css {
  display: flex;
  justify-content: center;
  color: #ececec;
  min-height: 20px;
}
#Content9_0 .timeline > .block-wrapper > .lhenscz5rp-editor_css {
  display: inline-block;
  justify-content: center;
  color: #ececec;
}
#Feature5_0 .ant-tabs-tab-active > .content7-tag > .lf29xeimtpg-editor_css {
  color: #75dfee;
}
#Content9_0 .text-wrapper > .block-title > .lhesld69os-editor_css {
  left: -45px;
}
#Content9_0 .text-wrapper > .block-title > .lhesm5nuspe-editor_css {
  left: -45px;
}
#Content9_0 .block-wrapper > .image-wrapper > .lhep8dinuyp-editor_css {
  display: flex;
  justify-content: center;
  width: 300px;
  max-width: 85%;
  height: 220px;
  max-height: 200px;
  min-height: 85%;
  position: relative;
  float: none;
  clear: left;
  padding: 10px 10px 10px 10px;
}
#Content9_0.lhete01cda-editor_css {
  padding: 0 0 0;
}
#Content9_0 .block-wrapper > .text-wrapper > .lheqiduwvp-editor_css {
  display: inline-block;
  align-items: baseline;
  font-weight: 300;
  color: #cbadff;
  text-align: start;
  padding: 0 5px 0 0;
}
#Content9_0 .timeline > .block-wrapper > .lheorrvnyna-editor_css {
  display: flex;
  padding: 0px 10px 10px 45px;
  font-size: 14px;
  line-height: 1.25;
  font-weight: 300;
}
#Content9_0 .lhenqc0s6rb-editor_css {
  max-width: 1400px;
  background-color: #131212;
  background-image: linear-gradient(rgba(0, 0, 0, 0.91) 0%, rgba(0, 0, 0, 0.4) 100%), url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg');
  background-attachment: scroll, scroll;
  background-blend-mode: normal, normal;
  background-position: 50% 0%, 50% 0%;
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  background-clip: padding-box, padding-box;
}
#Content9_0 .home-page > .lheos065c9-editor_css {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}

